import Vue from 'vue'
import VueRouter from 'vue-router'

import mobile from './mobile'
import pc from './pc'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const isMibile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: isMibile ? mobile : pc,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // 百度统计
  if (window._hmt) {
      if (to.path) {
          window._hmt.push(['_trackPageview', to.fullPath])
      }
  }
  next()
});

export default router
