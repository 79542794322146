<template>
  <swiper class="swiper" :options="swiperOption" ref="mySwiper" v-if="swiperList.length > 0">
    <swiper-slide v-for="item in swiperList" :key="item.title">
      <img :src="item.data.mobilePicture" />
      <div class="name">
        {{item.title}}
      </div>
      <div class="click" @click="handleExplore(item)" v-if="item.data.url">
        <span>点击探索</span>
        <img src="@/assets/home/arraw_m.png" alt="">
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination" />
  </swiper>
</template>
<script>
import Vue from 'vue'
import VueAwesomeSwiper, { Swiper, SwiperSlide  } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import banner1 from '@/assets/new/home/home_2_1_0511.png'
import banner2 from '@/assets/new/home/home_2_2_0511.png'
import { getStarModels } from '@/request/api';

Vue.use(VueAwesomeSwiper)

export default {
  name: 'SwiperComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      swiperList: [
        // {
        //   img: banner1,
        //   text: '花仙子',
        //   link: '/PixieDetail',
        // }, {
        //   img: banner2,
        //   text: 'CiCi',
        //   link: '/CICIDetail',
        // },
      ],
    }
  },
  setup() {
    return {};
  },
  methods: {
    handleExplore(item){
      this.$router.push(item.data.url)
      window._hmt.push(['_trackEvent', '官网首页', '车型探索', item.title]);
    }
  },
  mounted() {
    getStarModels().then(res => {
      // console.log(res)
      this.swiperList = res.data;
    })
  },
};
</script>
<style scoped lang="scss">
.swiper {
  position: relative;
  width: 100%;
  // height: calc(100vh - 60px);
  // height: calc(100vh - 90px - 120px);
  height: 165.733333vw;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination  {
  bottom: 50px;

  ::v-deep .swiper-pagination-bullet {
    width: 48px;
    height: 2px;
    background: #FFFFFF;
    opacity: 0.3;
    border-radius: 0;
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}
.name {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 250px;
  line-height: 1;
  font-size: 34px;
  font-family: 'HarmonyOS_Sans_SC_Bold';
  color: #fff;
}
.click {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: 125px;
  transform: translate(-50%, 0);
  width: 390px;
  height: 66px;
  border: 3px solid #fff;
  border-radius: 32px;
  font-family: 'HarmonyOS_Sans_SC_Medium';

  font-size: 26px;
  color: #fff;

  span {
    margin: 0 2px 0 0;
  }

  img {
    margin: 0 0 0 2px;
    height: 48px;
    width: auto;
  }
}
</style>
