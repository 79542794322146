<template>
  <div class="HomeGlobalStore">
    <IsVisible
      @visible="handleVisible(true)"
      @leave="handleVisible(false)"
      :class="{ 'animate__animated  animate__fadeInUp': visible }"
    >
      <ul class="HomeGlobal">
        <li
          v-for="(item, index) in globalList"
          :key="item.text"
          :class="{ active: activeIndex === index }"
          @click="toggle(index)"
          @mouseout="toggle()"
        >
            <img :src="item.img" alt="">
            <span class="text">{{ item.text }}</span>
        </li>
      </ul>
    </IsVisible>
    <IsVisible
      @visible="handleScroll(true)"
    >
      <div class="HomeStore" ref="HomeStore">
        <ul>
          <li v-for="(item, idx) in list" class="item" :key="idx">
            <span class="text">
              <CountUp
                :endVal="item.num"
                :options="options"
              />
              <b>{{item?.uitl}}</b>
            </span>
            <span class="num">{{item?.text}}</span>
          </li>
        </ul>
      </div>
    </IsVisible>
  </div>
</template>
<script>
import IsVisible from '@/components/IsVisible'
import CountUp from 'vue-countup-v2'
import Img1 from '@/assets/home/city_1.png'
import Img2 from '@/assets/home/city_2.png'
import Img3 from '@/assets/home/city_3.png'
import Img4 from '@/assets/home/city_4.png'
import Img5 from '@/assets/home/city_5.png'
import Img6 from '@/assets/home/city_6.png'
import Img7 from '@/assets/home/city_7.png'
import Img8 from '@/assets/home/city_8.png'
import Img9 from '@/assets/home/city_9.png'
import Img10 from '@/assets/home/city_10.png'

import { getConfig } from '@/request/api'
export default {
  name: 'HomeStore',
  components: {
    CountUp,
    IsVisible,
  },
  data() {
    return {
      visible: false,
      activeIndex: -1,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
        // 动画持续时间
        duration: 10,
      },
      globalList: [
        {
          text: 'THE UNITED STATES OF AMERICA',
          img: Img1,
        }, {
          text: 'SPAIN',
          img: Img2,
        }, {
          text: 'THE NETHERLANDS',
          img: Img3,
        }, {
          text: 'THE REPUBLIC OF KOREA',
          img: Img4,
        }, {
          text: 'GERMANY',
          img: Img5,
        }, {
          text: 'BRITAIN',
          img: Img6,
        }, {
          text: 'ITALY',
          img: Img7,
        }, {
          text: 'THAILAND',
          img: Img8,
        }, {
          text: 'GREECE',
          img: Img9,
        }, {
          text: 'EGYPT',
          img: Img10,
        }
      ],
      num: [0, 0, 0],
      list: [],
      timer: null,
    }
  },
  setup() {
    return {};
  },
  mounted() {
    this.timer = setInterval(
      () => {
        let newActiveIndex = this.activeIndex + 1
        if (newActiveIndex > 9) {
          newActiveIndex = 0
        }
        this.activeIndex = newActiveIndex
      },
      3000,
    )
    this.getList()
  },
  methods: {
    getList() {
      getConfig().then(res => {
        this.list = res.data[0].data;
        this.list.map(v => (v.num = parseInt(v.num)))
      })
    },
    handleVisible(visible) {
      if (visible) {
        this.visible = visible
      }
    },
    handleScroll() {
      this.num = this.list.map(v => (v.num * 1))
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? -1 : index
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
<style scoped lang="scss">
.HomeGlobalStore {
  width: 100%;
  // height: calc(100vh - 120px - 90px);
  height: 1250px;
}
.HomeGlobal {
  display: flex;
  position: relative;
  width: 100%;
  // height: calc(100vh - 120px - 90px - 200px);
  height: 730px;

  li {
    position: relative;
    width: calc(100% / 10);
    transition: all 0.3s ease;
  }

  span {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: rotate(90deg);
    transform-origin: top left;
    margin: 0 0 0 10px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .active {
    width: 268px;
  }
}
.HomeStore {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 530px;

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 530px;
    background: url('@/assets/home/store_bg_m.png') no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-size: cover;

    .item {
      display: inline-block;
      color: #fff;
      text-align: center;

      .text {
        font-size: 34px;
      }

      .num {
        font-size: 16px;
        display: block;
        margin-top: 18px;
      }

      b {
        font-weight: normal;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
