import {
    get,
    post
} from './http'

// 获取门店
export const getQutlet = p => get(`/api/web/stores/list?province=${p.province}&city=${p.city}&lat=${p.lat}&lng=${p.lng}`);

//获取省市
export const getProvince = p => get('/api/web/stores/list_province', p);

//获取最新资讯轮播图
export const getNewsCarousel = p => get('/api/web/news/carousel', p);

//获取最新资讯列表
export const getNewsList = p => get('/api/web/news/list', p);

//获取最新资讯详情
export const getNewsDetails = p => get('/api/web/news/' + p);

// 获取图形验证码
export const getCode = p => get('/api/captchaImage', p);

// 获取新日历程
export const getMileage = p => get('/api/web/progress/list', p);

// 获取官网里程数据
export const getConfig = p => get('/api/web/website/data/website_config', p);

// 获取车型参数
export const getCarParameters = p => get(`/api/web/vehicle/${p.id}`)

// 预约试驾
export const postTestDrive = p => post('/api/web/booking', p);

// 防伪
export const getAntFake = p => get('/api/web/mes', p);

// 上传
export const upload = (p, isUpload) => post('/api/common/upload', { p, isUpload });

// 供应商提交
export const supplierSubmit = p => post('/api/web/supplier', p);

// 经销商提交
export const dealerSubmit = p => post('/api/web/dealer', p);

// 获取招标列表
export const getTenderingList = p => get('/api/web/bidding/list', p);

// 获取招聘列表
export const getRecruit = p => get('/api/web/recruitment/list', p);

// 获取新日周边轮播图
export const goodsBanner = p => get('/api/web/goods/carousel/list', p);

// 获取新日周边分类
export const goodsClassify = p => get('/api/web/goods/category/list', p);

// 获取新日周边分类
export const goodsList = p => get('/api/web/goods/list', p);

// 获取车型列表
export const getCarList = p => get('/api/web/vehicle/list', p);

// 获取车型列表类
export const getCarClassify = p => get(`/api/web/vehicle/${p}`);

// 车型图片颜色列表
export const getCarColor = p => get(`/api/web/vehicle/image/list`, p);

// 获取手机验证码
export const getPhoneCode = p => get('/api/web/phone_captcha', p);

// 获取车型列表
export const getCarTypeList = p => get('/api/web/vehicle/list', p);

// 上传简历提交
export const sRecruitSubmit = p => post('/api/web/recruitment/resume', p);

// 获取新闻资讯车型列表(新)
export const getNewsCartype = p => get('/api/web/news/category/list', p);

// 获取新闻资讯车型列表
export const getCurrentProvince = p => get('/api/common/list_province_city', p);

// 获取商城商品小程序跳转url
export const goodsLink = p => get('/api/web/wechat/link/goods', p);

// 获取一键救援跳转url
export const rescueLink = p => get('/api/web/wechat/link/road_help', p);

// 获取售后跳转url
export const AfterSaleLink = p => get('/api/web/wechat/link/after_sales', p);

// 预约试驾车型列表
export const getCarListDriver = p => get('/api/web/vehicle/list/booking', p);

// 获取下载中心列表
export const getDownloadList = p => get('/api/web/download_center/list', p);

// 下载中心登录
export const downloadLogin = p => get('/api/web/download_center/login', p);

// 获取栏目
export const getMenu = p => get('/api/web/page_column', p);

// 获取首页第一屏轮播
export const getHomeCarousel = p => get('/api/web/website/data/home_carousel', p);

// 获取首页明星车型轮播
export const getStarModels = p => get('/api/web/website/data/star_models', p);

// 获取首页前沿技术
export const getForefrontTech = p => get('/api/web/website/data/forefront_tech', p);

// 页面管理
export const getParams = p => get('/api/web/page_content', p);
