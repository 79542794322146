<template>
  <div class="HomeServer">
    <ul>
      <li v-for="(item, i) in list" :key="i" :class="{ cur: cur == i }" @click="handleClick(i, item)">
        <IsVisible @visible="handleVisible(i, true)" @leave="handleVisible(i, false)"
          :class="{ 'animate__animated  animate__fadeInUp': visible[i] }" :offset=0>
          <p>{{ item.text }}</p>
          <img :src="item.img" alt="">
        </IsVisible>
      </li>
    </ul>
  </div>
</template>
<script>
import IsVisible from '@/components/IsVisible'
import img1 from '@/assets/new/home/home_5_1.jpg'
import img2 from '@/assets/new/home/home_5_2.jpg'
import img3 from '@/assets/new/home/home_5_3.jpg'
export default {
  name: 'HomeServer',
  components: {
    IsVisible,
  },
  data() {
    return {
      visible: [false, false, false],
      cur: 0,
      list: [{
        text: '智慧出行APP',
        img: img1
      }, {
        text: '24小时道路救援',
        img: img2,
        path: '/Rescue'
      }, {
        text: '服务体验中心',
        img: img3,
        path: '/AfterSale'
      }]
    }
  },
  setup() {
    return {};
  },
  mounted() {
    this.visible = new Array(this.visible.length).fill(false)
  },
  methods: {
    handleVisible(idx, visible) {
      if (visible) {
        this.$set(this.visible, idx, visible)
        /*
      } else {
        setTimeout(() => {
          this.$set(this.visible, idx, visible)
        }, 500)
        */
      }
    },
    handleClick(i, item) {
      this.cur = i
      this.$router.push(this.list[i].path)
      window._hmt.push(['_trackEvent', '官网首页', '页面正文', item.text]);
    }
  },
};
</script>
<style scoped lang="scss">
.HomeServer {
  width: 100%;

  ul {
    margin: 0;

    li {
      display: block;
      margin: 12px 0 0;
      width: 100%;
      position: relative;

      &:first-child {
        margin: 4px 0 0;
      }

      &>div {
        width: 100%;
        height: 100%;
        transform: translate3d(0, 30%, 0);
      }

      &:nth-child(2) {
        p {
          color: #fff;
        }
      }

      img {
        width: 100%;
        height: 100%;
        vertical-align: bottom;
      }
    }

    p {
      margin: 0;
      position: absolute;
      top: 51px;
      right: 0;
      left: 0;
      height: 32px;
      line-height: 1;
      font-size: 32px;
      font-family: "HarmonyOS_Sans_SC_Medium";
      color: #000;
      text-align: center;

      &::after {
        position: absolute;
        bottom: -12px;
        left: 50%;
        width: 92px;
        height: 3px;
        background: #f3bd0b;
        transform: translate(-50%, 0);
        content: "";
      }
    }
  }
}
</style>
