<template>
  <IsVisible
    @visible="handleVisible(true)"
    @leave="handleVisible(false)"
    :class="{ 'animate__animated  animate__fadeInUp': visible }"
  >
    <div class="HomeServer">
      <ul>
        <li
          v-for="item in list"
          :key="item.img"
          @click="handleServer(item)"
        >
          <img :src="item.img" alt="">
          <div>{{item.text}}</div>
        </li>
      </ul>
    </div>
  </IsVisible>
</template>
<script>
import IsVisible from '@/components/IsVisible'
import img1 from '@/assets/new/home/service_1.jpg'
import img2 from '@/assets/new/home/service_2.jpg'
import img3 from '@/assets/new/home/service_3.jpg'

export default {
  name: 'HomeServer',
  components: {
    IsVisible,
  },
  data() {
    return {
      visible: false,
      cur: 0,
      list: [
        {
          text: '智慧出行APP',
          img: img1
        }, {
          text: '24小时道路救援',
          img: img2,
          path:'/Rescue'
        }, {
          text: '服务体验中心',
          img: img3,
          path:'/AfterSale'
        },
      ],
    }
  },
  setup() {
    return {};
  },
  methods: {
    handleVisible(visible) {
      if (visible) {
        this.visible = visible
        /*
      } else {
        setTimeout(() => {
          this.visible = visible
        } 500)
        */
      }
    },
    handleServer(item){
      this.$router.push(item.path)
      window._hmt.push(['_trackEvent', '官网首页', '页面正文', item.text]);
    }
  },
};
</script>
<style scoped lang="scss">
.HomeServer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100% - 122px - 122px);
  min-width: 960px;
  // height: calc(100vh - 60px);
  margin: 100px auto;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;

    li {
      width: calc(100% / 3 - 16px);
      position: relative;
      transition: all 0.3s ease;
      overflow: hidden;
      cursor: pointer;

      img {
        transition: all 0.5s ease-in;
      }

      &:nth-child(2) {
        div {
          color: #fff;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        div {
          &::after {
            width: 58px;
          }
        }
      }
    }

    div {
      position: absolute;
      top: 34px;
      right: 0;
      left: 0;
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      font-family: 'harmonyos_sans_sc_medium';
      color: #000;
      text-align: center;
      transition: all 0.5s ease-in;

      &::after {
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 0px;
        height: 2px;
        background: #f3bd0b;
        transform: translate(-50%, 0);
        content: "";
        transition: all 0.5s ease-in;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .HomeServer ul div {
    top: 16px;
    font-size: 18px;
  }
}
</style>
