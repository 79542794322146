<template>
  <div class="first" v-if="swiperList.length > 0">
    <swiper class="swiper" :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="item in swiperList" :key="item.title">
        <img class="video" v-if="item.data.video" src="@/assets/common/grayplay.png" @click="operateVideo(item)" />
        <video v-if="item.data.video" muted loop :poster="item.data.mobilePicture">
          <source :src="item.data.video" type="video/mp4">
        </video>
        <img v-else class="img" :src="item.data.mobilePicture" @click="clickImg(item)" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination" />
    </swiper>
    <div ref="myVideo" class="popVideo" v-if="videoState">
      <div class="video">
        <video ref="myVideo" :src="video" controls autoplay>
        </video>
        <div class="video_close" @click="closeVideo">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueAwesomeSwiper, { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import video1 from '@/assets/home/home_1_1.mp4'

import banner1 from '@/assets/new/home/banner_1_1_0329.jpg'
import banner2 from '@/assets/new/home/banner_1_2.jpg'
import banner3 from '@/assets/new/home/banner_1_3.jpg'
import banner4 from '@/assets/new/home/banner_1_4.jpg'
import videobtn from '@/assets/common/grayplay.png'

import { getHomeCarousel } from '@/request/api';

Vue.use(VueAwesomeSwiper)

export default {
  name: 'SwiperComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      videoState: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        // loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      swiperList: [
        // {
        //   img: banner1,
        //   video: null,
        //   btn: videobtn,
        // }, {
        //   img: banner2,
        //   video: null,
        // }, {
        //   img: banner3,
        //   video: null,
        //   text: '花仙子',
        //   link: '/PixieDetail',
        // }, {
        //   img: banner4,
        //   video: null,
        //   text: 'CiCi',
        //   link: '/CICIDetail',
        // },
      ],
      video: ''
    }
  },
  setup() {
    return {};
  },
  methods: {
    // 操作视频播放、暂停
    operateVideo(item) {
      console.log(item)
      this.video = item.data.video;
      this.videoState = true;
    },
    closeVideo() {
      this.videoState = false;
    },
    clickImg(item) {
      if (item.title) {
        window._hmt.push(['_trackEvent', '官网首页', '头部轮播', item.title]);
      }
      if (item.data.url) {
        this.$router.push(item.data.url)
      }
    }
  },
  mounted() {
    getHomeCarousel().then(res => {
      // console.log(res)
      this.swiperList = res.data;
    })
  }
};
</script>
<style scoped lang="scss">
.first {
  width: 100%;
  height: 153.3333vw;
}

.swiper {
  width: 100%;
  // height: calc(100vh - 60px);
  // height: calc(100vh - 90px - 120px);
  height: 153.3333vw;
}

.swiper-slide {
  position: relative;
  text-align: center;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide .video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
  // background: red;
}

.swiper-slide .img,
.swiper-slide video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  bottom: 60px;

  ::v-deep .swiper-pagination-bullet {
    width: 48px;
    height: 2px;
    background: #FFFFFF;
    opacity: 0.3;
    border-radius: 0;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

.popVideo {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999 !important;
  background: rgba(0, 0, 0, .85);

  .video {
    outline: none;
    max-width: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 20px 40px rgb(0 0 0 / 50%);

    video {
      width: 100%;
      height: 100%;
    }
  }

  .video_close {
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    cursor: pointer;
    font-size: 30px;
  }

}
</style>

