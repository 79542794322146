import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'

/* element ui */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'

import router from './router'
import store from './store'

import '@/utils/rem.js'

import './assets/iconfont/iconfont.css'
import './theme/element-variables.scss'
import 'amfe-flexible'

import '@/assets/font/font.css'

import '@/theme/animation.css'
import VueAnimateOnScroll from '@/utils/animateScroll.js'
Vue.use(VueAnimateOnScroll)

Vue.use(ElementUI);
Vue.config.productionTip = false

import cookies from 'vue-cookies'
Vue.prototype.$cookies = cookies;

// console.log(process.env.NODE_ENV)
// 环境的切换
if (process.env.NODE_ENV == 'development') {
  Vue.prototype.downURL = 'https://xinri-cms-dev.powermarketing.com.cn';
} else if (process.env.NODE_ENV == 'production') {
  Vue.prototype.downURL = '';
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

// 百度统计
var _hmt = _hmt || [];
window._hmt = _hmt;
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?450654d3f4d7b5f46260a31276cdb694";
  var s = document.getElementsByTagName("script")[0];
  // console.log(s)
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
