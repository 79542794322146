<template>
  <div class="footer">
    <div class="footer_box">
      <div class="foot_nav">
        <ul>
          <li v-for="item in list" :key="item.text">
            <div>{{ item.text }}</div>
            <span v-for="sub in item.children" :key="sub.text" @click="handleJump(sub, item)">{{ sub.text }}</span>
          </li>
        </ul>
      </div>
      <div class="foot_right">
        <div class="foot_tel">
          <i><img :src="telImg" /></i>全国客户服务热线<span>400 888 6999</span>
        </div>
        <div class="foot_icon">
          <i v-for="(item, i) in foot_imgs" :key="i">
            <img v-if="item.show" class="qr" :src="item.QRcode" alt="">
            <img :src="item.iconImg" @mouseover="mouseover(i)" @mouseout="mouseout(i)" @click="mouseclick(item)" />
          </i>
        </div>
      </div>
    </div>
    <div class="foot_text">
      <div class="inl">© 2023 江苏新日电动车股份有限公司 公司地址:江苏省无锡市锡山区锡山大道501号</div>
      <div class="inl ba" @click="handeleBA">苏ICP备12037028号-1</div>
    </div>
  </div>
</template>
<script>
import wechat from '../assets/common/wechat.png'
import sina from '../assets/common/sina.png'
import customer from '../assets/common/customer.png'
import douyin from '../assets/common/douyin.png'
import tel from '../assets/common/tel.png'
import wechat_qr from '../assets/footer/weixin.png'
import sina_qr from '../assets/footer/weibo.png'
import douyin_qr from '../assets/footer/douyin.jpeg'

export default {
  name: 'FooterComponent',
  data() {
    return {
      foot_imgs: [
        {
          iconImg: wechat,
          QRcode: wechat_qr,
          show: false
        },
        {
          iconImg: sina,
          QRcode: sina_qr,
          show: false
        },
        {
          iconImg: customer,
          path: 'https://xinri.s4.udesk.cn/im_client/?web_plugin_id=236&group_id=45',
        },
        {
          iconImg: douyin,
          QRcode: douyin_qr,
          show: false
        },
      ],
      telImg: tel,
      list: [{
        text: '品牌合作',
        children: [{
          text: '成为经销商',
          path: '/Dealer'
        }, {
          text: '成为供应商',
          path: '/Supplier'
        }, {
          text: '下载中心',
          path: '/Download',
          // path: 'https://www.xinri.com/download'
        }, {
          text: '联系我们',
          path: '/JoinUs'
        }]
      }, {
        text: '新日动态',
        children: [
          {
            text: '新日里程',
            path: '/MileageView',
          }, {
            text: '最新资讯',
            path: '/News'
          }, {
            text: '投资者关系',
            path: 'http://www.sse.com.cn/assortment/stock/list/info/announcement/index.shtml?productId=603787',
            blank: true,
          }, 
          // {
          //   text: '新日公示',
          //   path: '/Publicity'
          // }
        ]
      }, {
        text: '在线购买',
        children: [{
          text: '天猫旗舰店',
          path: 'https://sunra.tmall.com/shop/view_shop.htm?spm=a230r.1.14.29.751d1136FriHqk&user_number_id=3299935258',
          blank: true,
        }, {
          text: '京东官方旗舰店',
          path: 'https://mall.jd.com/index-65252.html?from=pc',
          blank: true,
        }, {
          text: '京东自营旗舰店',
          path: 'https://mall.jd.com/index-1000324841.html?from=pc',
          blank: true,
        }, {
          text: '拼多多官方旗舰店',
          path: 'https://mobile.yangkeduo.com/mall_page.html?_x_org=1&refer_share_uin=TV2UKSUX2PEQNPUVG5NZH3KRKY_GEXDA&refer_share_id=SQEPgOziGElk4c8T7TyVzImJqDOikHid&_x_query=%E6%96%B0%E6%97%A5qc52.0&_wv=41729&refer_share_channel=copy_link&msn=4kunvhllu3hbumsrzgh2ynxm24_axbuy&has_decoration=1&mall_id=624046988&_wvx=10',
          blank: true,
        }]
      }, {
        text: '车主服务',
        children: [
          {
            text: '一键救援',
            path: '/enjoyment'
          }, {
            text: '网点查询',
            path: '/OutletQuery'
          },
          //  {
          //   text: '智选周边',
          //   path: '/Goods'
          // }
        ]
      }, {
        text: '招贤纳士',
        children: [{
          text: '校园招聘',
          path: '/CampusRecruit'
        }, {
          text: '社会招聘',
          path: '/SocialRecruit'
        }]
      }, {
        text: '投诉举报',
        children: [{
          text: '在线投诉',
          path: '/Complaint',
          // blank: 'inside',
        }]
      }]
    }
  },
  methods: {
    handeleBA() {
      window.open('https://beian.miit.gov.cn/');
    },
    handleJump(item, f) {
      console.log(item)
      console.log(f)
      window._hmt.push(['_trackEvent', '底部导航栏', f.text, item.text]);
      if (item.path && item.blank === true) {
        window.open(item.path, '_blank')
      } else if (item.path && item.blank === 'inside') {
        location.href = item.path
      }  else if (item.path) {
        this.$router.push(item.path)
      }
    },
    mouseover(i) {
      this.foot_imgs[i].show = !this.foot_imgs[i].show
    },
    mouseout(i) {
      this.foot_imgs[i].show = !this.foot_imgs[i].show
    },
    mouseclick(item) {
      if (item.path) {
        window.open(item.path, '_blank')
      }
    },
  }
}
</script>
<style scoped lang="scss">
.footer {
  box-sizing: border-box;
  background: #1c1c1f;
  left: 0;
  width: 100%;
  // min-width: 1124px;
  padding: 70px 120px 0;
}

.footer_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.1);
}

.foot_nav {
  width: calc(100% - 272px);
  max-width: 722px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  li {
    div {
      font-size: 14px;
      color: #ffffff;
      font-family: "HarmonyOS_Sans_Medium";
    }

    span {
      display: block;
      margin-top: 20px;
      font-size: 14px;
      color: #95999f;
      cursor: pointer;
    }
  }
}

.foot_right {
  // padding-left: 50px;
  // width: 342px;
  // text-align: right;

  .foot_tel {
    margin: 0 0 56px 0;
    padding-left: 30px;
    font-size: 16px;
    color: #95999f;

    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }

    span {
      font-family: "HarmonyOS_Sans_Medium";
      color: #ffc600;
      display: inline-block;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid rgba(149, 153, 159, 0.4);
    }
  }

  .foot_icon {
    // text-align: center;

    i {
      display: inline-block;
      width: 38px;
      height: 38px;
      margin-left: 30px;
      cursor: pointer;
      position: relative;
    }

    .qr {
      width: 126px;
      height: 126px;
      position: absolute;
      top: -126px;
      left: -45px;
    }
  }
}

.foot_text {
  margin: 8px auto;
  padding-bottom: 16px;
  color: #95999f;
  font-size: 12px;

  .inl {
    display: inline-block;
  }
  .ba {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
