import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    token: '',
    narList: [
      {
        text: "产品概述",
        checked: true,
        url: "/M_MIKUSuperDetail"
      },
      {
        text: "参数配置",
        checked: false,
        url: "/M_MIKUSuperConfig"
      },
    ]
  },
  getters: {
  },
  mutations: {
    increment(state) {
      state.count++
    }
  },
  actions: {
  },
  modules: {
  }
})
