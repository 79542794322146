<template>
  <div class="side">
    <ul>
      <li v-for="item in list" :key="item.img" @click.stop="handleJump(item)" class="nav">
        <div>
          <img :src="item.img" alt="">
        </div>
        <ul v-if="item.children">
          <li v-for="store in item.children" :key="store.path" @click.stop="handleJump(store)">
            {{ store.text }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import banner1 from '@/assets/new/common/kefu.png'
import banner2 from '@/assets/new/common/buy.png'
import banner3 from '@/assets/new/common/drive-icon.png'
import banner4 from '@/assets/new/common/top.png'

export default {
  name: 'HomeSide',
  components: {},
  data() {
    return {
      list: [
        {
          img: banner1,
          path: 'https://xinri.s4.udesk.cn/im_client/?web_plugin_id=236&group_id=45',
          title: '在线客服',
          children: [
            {
              text: '在线客服',
              path: 'https://xinri.s4.udesk.cn/im_client/?web_plugin_id=236&group_id=45',
              title: '在线客服',
            },
          ],
        }, {
          img: banner2,
          path: '',
          children: [
            {
              text: '天猫旗舰店',
              path: 'https://sunra.tmall.com/shop/view_shop.htm?spm=a230r.1.14.29.751d1136FriHqk&user_number_id=3299935258',
              title: '在线购买',
            },
            {
              text: '京东官方旗舰店',
              path: 'https://mall.jd.com/index-65252.html?from=pc',
              title: '在线购买',
            },
            {
              text: '京东自营旗舰店',
              path: 'https://mall.jd.com/index-1000324841.html?from=pc',
              title: '在线购买',
            },
            {
              text: '拼多多官方旗舰店',
              path: 'https://mobile.yangkeduo.com/login.html?from=https%3A%2F%2Fmobile.yangkeduo.com%2Fmall_page.html%3F_x_org%3D1%26refer_share_uin%3DTV2UKSUX2PEQNPUVG5NZH3KRKY_GEXDA%26refer_share_id%3DSQEPgOziGElk4c8T7TyVzImJqDOikHid%26_x_query%3D%25E6%2596%25B0%25E6%2597%25A5qc52.0%26_wv%3D41729%26refer_share_channel%3Dcopy_link%26msn%3D4kunvhllu3hbumsrzgh2ynxm24_axbuy%26has_decoration%3D1%26mall_id%3D624046988%26_wvx%3D10&_x_org=1&_x_share_id=SQEPgOziGElk4c8T7TyVzImJqDOikHid&_x_query=%E6%96%B0%E6%97%A5qc52.0&refer_page_name=mall_page&refer_page_id=10039_1683597040501_g8okigozwu&refer_page_sn=10039',
              title: '在线购买',
            },
          ],
        },
         {
          img: banner3,
          path: '/TestDrive',
          title: '预约试骑',
          children: [
            {
              text: '预约试骑',
              path: '/TestDrive',
              title: '预约试骑',
            },
          ],
        }, 
        {
          img: banner4,
          top: true,
        },
      ],
    }
  },
  setup() {
    return {};
  },
  mounted() {
    // console.log('右侧浮窗');
  },
  methods: {
    handleJump(item) {
      console.log(item);
      if (item.path) {
        window._hmt.push(['_trackEvent', '右侧浮窗', item.title, item.text ? item.text : '在线客服']);
        if (item.title == '预约试骑') {
          if (this.$route.meta.id) {
            this.$router.push({ path: item.path, query: { carID: this.$route.meta.id } })
          } else {
            this.$router.push(item.path)
          }
        }
        else window.open(item.path, '_blank')
      } else if (item.top) {
        window._hmt.push(['_trackEvent', '右侧浮窗', '返回顶部', '返回顶部']);
        const scrollToTopInterval = setInterval(() => {
          if (window.scrollY > 0) {
            window.scrollTo(0, window.scrollY - 20);
          } else {
            clearInterval(scrollToTopInterval);
          }
        }, 1);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.side {
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 100;
  width: 64px;
  background: rgba(0, 0, 0, 0.2);

  .nav {
    position: relative;
    width: 64px;
    height: 68px;
    cursor: pointer;
    margin: 0;

    &:last-child {
      margin: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      content: "";
      height: 1px;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.4);
    }

    & > div {
      width: 64px;
      height: 60px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: inline-block;
      width: 36px;
      height: 36px;
    }

    &:hover {
      ul {
        display: block;
      }
    }

    ul {
      display: none;
      position: absolute;
      top: 0;
      right: 64px;
      width: 165px;
      padding: 17px 0 17px;
      background: rgba(0, 0, 0, 0.2);

      li {
        position: relative;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        font-family: "HarmonyOS_Sans_SC_Regular";
        color: #fff;

        &:after {
          display: none;
          position: absolute;
          top: 50%;
          left: 0;
          bottom: 0;
          transform: translateY(-50%);
          width: 4px;
          height: 28px;
          background: #ffc600;
          overflow: hidden;
          content: " ";
        }

        &:hover::after {
          display: block;
        }
      }
    }
  }
}
</style>
