<template>
  <div>
    <slot :isVisible="isVisible"></slot>
  </div>
</template>

<script>
export default {
  name: 'IsVisible',
  props: {
    root: {
      type: Element,
      default: null,
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: Number,
      default: 0,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersect, {
      root: this.root,
      rootMargin: this.effectiveRootMargin,
      threshold: this.threshold,
    });

    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer.disconnect();
    this.handleLeave();
  },
  methods: {
    handleIntersect(entries) {
      const entry = entries[0];
      this.isVisible = entry.isIntersecting;

      if (this.isVisible) {
        this.$emit('visible');
      } else {
        this.handleLeave();
      }
    },
    handleLeave() {
      this.isVisible = false;
      this.$emit('leave');
    },
  },
  computed: {
    effectiveRootMargin() {
      const marginParts = this.rootMargin.split(/\s+/);
      const top = `${parseInt(marginParts[0] || '0', 10) - this.offset}px`;
      const right = marginParts[1] || top;
      const bottom = marginParts[2] || top;
      const left = marginParts[3] || right;

      // console.log(`${top} ${right} ${bottom} ${left}`)

      return `${top} ${right} ${bottom} ${left}`;
    },
  },
};
</script>
