<template>
  <IsVisible @visible="handleVisible(true)" @leave="handleVisible(false)" class="HomeTechnology"
    :class="{ 'animate__animated  animate__fadeInUp': visible }" v-if=imgSrc>
    <div class="HomeTechnology">
      <!--
      <video
        autoplay
        muted
        loop
      >
        <source
          src="@/assets/home/home_1_1.mp4"
          type="video/mp4"
        >
      </video>
      -->
      <img :src="imgSrc" />
      <div class="click" @click="handleJump" v-if=url>
        <span>点击探索</span>
        <img src="@/assets/home/arraw_m.png" alt="">
      </div>
    </div>
  </IsVisible>
</template>
<script>
import IsVisible from '@/components/IsVisible'
import { getForefrontTech } from '@/request/api'
export default {
  name: 'HomeTechnology',
  data() {
    return {
      visible: false,
      imgSrc: '',
      url: '',
    }
  },
  components: {
    IsVisible,
  },
  setup() {
    return {};
  },
  methods: {
    handleVisible(visible) {
      if (visible) {
        this.visible = visible
      }
    },
    handleJump() {
      this.$router.push(this.url)
      window._hmt.push(['_trackEvent', '官网首页', '前沿技术', '双芯双动力系统 2.0']);
    }
  },
  mounted() {
    getForefrontTech().then(res => {
      // console.log(res)
      this.imgSrc = res.data[0]?.data.picture
      this.url = res.data[0]?.data.url
    })
  }
};
</script>
<style scoped lang="scss">
.HomeTechnology {
  position: relative;
  width: 100%;
  // height: calc(100vh - 60px);
  height: 56.25vw;

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.click {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: 52px;
  transform: translate(-50%, 0);
  width: 145px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 20px;
  font-family: HarmonyOS_Sans_SC_Medium;
  transition: all 0.3s ease;

  font-size: 12px;
  color: #fff;
  cursor: pointer;

  span {
    margin: 0 2px 0 0;
  }

  img {
    margin: 0 0 0 2px;
    width: 24px;
    height: auto;
  }

  &:hover {
    background: #E4B756;
    border: 1px solid #E4B756;
    transform: translate(-50%, 0) scale(1.1);
    color: #fff;
  }
}
</style>
