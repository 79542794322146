import { render, staticRenderFns } from "./IndexViewMobile.vue?vue&type=template&id=508df6f3&scoped=true"
import script from "./IndexViewMobile.vue?vue&type=script&lang=js"
export * from "./IndexViewMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508df6f3",
  null
  
)

export default component.exports