<template>
  <div class="m_header">
    <div class="head_wrap">
      <div class="head_cont">
        <img class="logo" src="@/assets/mobile_images/header/logo.png" alt="" @click="handleNav('/')">
        <div class="z_cont" @click="handleFold" v-if="!show">
          <img src="@/assets/mobile_images/header/zhedie.png" alt="">
        </div>
        <div class="z_cont cha" @click="handleFold" v-else>
          <img src="@/assets/mobile_images/header/cha.png" alt="">
        </div>
      </div>
    </div>
    <div class="drop_down_list" v-if="show">
      <el-collapse v-model="activeName1" accordion class="f" @click="show_collapse">
        <el-collapse-item v-for="item in list" :key="item.title" :title="item.title" :name="item.title"
          @click.native="show_collapse(item)">
          <template slot="title">
            {{ item.title }}
            <img v-if="item.title != activeName1" src="@/assets/mobile_images/header/jia.png" alt="">
            <img v-else src="@/assets/mobile_images/header/cha.png" alt="">
          </template>
          <div class="s">
            <el-collapse v-model="activeName2" accordion>
              <div v-for="c_item in item.children" :key="c_item.title">
                <el-collapse-item v-if="c_item.children" :title="c_item.title" :name="c_item.title">
                  <div class="th_cont">
                    <div v-for="cc_item in c_item.children" :key="cc_item.title" @click="handleNav(cc_item, c_item)"
                      class="s_cont" :class="{ s_cont1: cc_item.image == '' }">
                      <div v-if="cc_item.image" class="img_car">
                        <img :src="cc_item.image" alt="">
                      </div>
                      {{ cc_item.title }}
                    </div>
                  </div>
                </el-collapse-item>
                <div v-else class="no_children" @click="handleNav(c_item, item)">
                  {{ c_item.title }}
                </div>
              </div>
            </el-collapse>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { getMenu } from '@/request/api'
export default {
  name: 'HeaderComponent',
  props: {
    msg: String
  },
  data() {
    return {
      show: false,
      activeName1: '',
      activeName2: '',
      list: [
        // {
        //   text: '探索车型',
        //   children: [
        //     {
        //       text: '双芯双动力系列',
        //       point_text: '探索车型-双芯双动力系列',
        //       path: '',
        //       children: [
        //         {
        //           text: '花仙子',
        //           path: '/Pixie',
        //           img: require('@/assets/mobile_images/header/huaxianzi.png')
        //         }, {
        //           text: 'CiCi',
        //           path: '/CICI',
        //           img: require('@/assets/mobile_images/header/cici.png')
        //         },
        //         // {
        //         //   text: '幻影F9',
        //         //   path: '/HyF9',
        //         //   img: require('@/assets/mobile_images/header/huanyingf9.png')
        //         // },
        //       ],
        //     }, {
        //       text: 'MIKU系列',
        //       point_text: '探索车型-MIKU系列',
        //       path: '',
        //       children: [
        //         {
        //           text: 'MIKU 1',
        //           path: '/MIKU1',
        //           img: require('@/assets/mobile_images/header/MIKU1.png')
        //         }, {
        //           text: 'MIKU Super',
        //           path: '/MIKUSuper',
        //           img: require('@/assets/mobile_images/header/MIKUSuper.png')
        //         },
        //       ],
        //     }
        //   ],
        // }, {
        //   text: '前沿技术',
        //   children: [
        //     {
        //       text: '汽车级锂电池',
        //       path: '/LithiumCell'
        //     },
        //     {
        //       text: '双芯双动力系统2.0',
        //       point_text: '双芯双动力系统2.0',
        //       path: '/DualPower'
        //     },
        //     {
        //       text: '智能操控系统',
        //       path: '/ControlSystem'
        //     },
        //   ],
        // }, {
        //   text: '车主服务',
        //   children: [
        //     {
        //       text: '使用指南',
        //       point_text: '车主服务-使用指南',
        //       path: '',
        //       noImg: true,
        //       children: [
        //         {
        //           text: "零部件三包标准",
        //           path: "/SanBao"
        //         },
        //         {
        //           text: "常见问题解答",
        //           path: "/Answer"
        //         },
        //         {
        //           text: "车辆保养",
        //           path: "/maintain"
        //         }
        //       ],
        //     },
        //     // {
        //     //   text: '网点查询',
        //     //   path: '/OutletQuery',
        //     // },
        //     {
        //       text: '智享服务',
        //       point_text: '车主服务-智享服务',
        //       path: '',
        //       noImg: true,
        //       children: [
        //         {
        //           text: "一键救援",
        //           path: "/Rescue"
        //         },
        //         {
        //           text: "预约售后",
        //           path: "/AfterSale"
        //         }
        //       ],
        //     }, {
        //       text: '智选周边',
        //       path: '/Goods',
        //     }, {
        //       text: '防伪查询',
        //       path: '/AntFake',
        //     },
        //   ],
        // }, {
        //   text: '探索新日',
        //   children: [
        //     {
        //       text: '了解新日',
        //       point_text: '探索新日-了解新日',
        //       path: '',
        //       noImg: true,
        //       children: [
        //         {
        //           text: "品牌简介",
        //           path: "/BrandStory"
        //         },
        //         {
        //           text: "技术实力",
        //           path: "/Technological"
        //         },
        //         {
        //           text: "合作伙伴",
        //           path: "/Cooperative"
        //         },
        //         {
        //           text: "品牌荣誉",
        //           path: "/Honor"
        //         },
        //         {
        //           text: "品牌愿景",
        //           path: "/Vision"
        //         },
        //       ],
        //     }, {
        //       text: '新日里程',
        //       path: '/MileageView'
        //     }, {
        //       text: '最新资讯',
        //       path: '/NewsExpress',
        //       noImg: true,
        //       children: [
        //         {
        //           text: "口碑文章",
        //           path: "/PublicPraise"
        //         },
        //         {
        //           text: "新闻速递",
        //           path: "/NewsExpress"
        //         },
        //         {
        //           text: "测评专栏",
        //           path: "/Evaluation"
        //         },
        //       ],
        //     }, {
        //       text: '加入我们',
        //       point_text: '探索新日-加入我们',
        //       path: '/JoinUs',
        //       noImg: true,
        //       children: [
        //         {
        //           text: "招贤纳士",
        //           path: "/JoinUs"
        //         },
        //         {
        //           text: "成为经销商",
        //           path: "/Dealer"
        //         },
        //         {
        //           text: "成为供应商",
        //           path: "/Supplier"
        //         },
        //         {
        //           text: "招标中心",
        //           path: "/Tendering"
        //         }
        //       ],
        //     },
        //   ],
        // },
      ],
    }
  },
  methods: {
    handleFold() {
      this.show = !this.show;
    },
    show_collapse() {
    },
    handleNav(n, c_item) {
      if (n === '/') {
        this.$router.push(n);
        document.querySelector('meta[name="keywords"]').setAttribute('content', '')
        document.querySelector('meta[name="description"]').setAttribute('content', '新日集团成立于1999年，从事绿色交通工具研发、生产与销售，是国内首家登录 A 股电动自行车企业。产品远销欧美和东南亚市场，出口全球近100个国家和地区。')
        this.show = false;
      } else {
        if (c_item.point_text) {
          window._hmt.push(['_trackEvent', '头部导航栏', c_item.point_text, n.text]);
        } else if (n.point_text) {
          window._hmt.push(['_trackEvent', '头部导航栏', this.activeName1, n.point_text]);
        } else {
          if (n.text == '新日里程' || n.text == '最新资讯') {
            window._hmt.push(['_trackEvent', '头部导航栏', n.text, n.text]);
          } else {
            window._hmt.push(['_trackEvent', '头部导航栏', this.activeName1, n.text]);
          }
        }
      }
      if (n.path) {
        if (n.path.indexOf("/param") != -1 && this.$route.path.indexOf("/param") != -1) {
          this.$router.push(n.path);
          location.reload();
          return
        }
        this.$router.push(n.path);
        document.querySelector('meta[name="keywords"]').setAttribute('content', '')
        document.querySelector('meta[name="description"]').setAttribute('content', '新日集团成立于1999年，从事绿色交通工具研发、生产与销售，是国内首家登录 A 股电动自行车企业。产品远销欧美和东南亚市场，出口全球近100个国家和地区。')
        this.show = false;
      }
    },
  },
  mounted() {
    getMenu({ type: 1 }).then(res => {
       console.log(res)
      this.list = res.data;
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.m_header {
  height: 90px;

  .head_wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    height: 90px;
    background: #fff;
  }

  .head_cont {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    height: 90px;
    background: #fff;

    .logo {
      width: 63px;
      height: 65px;
    }

    .z_cont {
      width: 74px;
      height: 100%;
      position: absolute;
      right: 0px;
      text-align: center;
      display: flex;
      align-items: center;

      img {
        width: 34px;
        height: 25px;
      }
    }

    .cha {
      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .drop_down_list {
    position: fixed;
    width: 100%;
    min-height: calc(100vh - 90px);
    z-index: 101;
    background: #fff;
    top: 90px;

    ::v-deep .f {
      border: none;

      .el-collapse-item__content {
        padding-bottom: 10px;

        // .el-collapse-item__header {
        //   width: calc(100% - 120px - 54px);
        //   margin: 0 0 0 120px;
        // }
      }

      .el-collapse-item__header.is-active {
        border-bottom: 1px solid #ffc600;
      }

      .el-collapse-item__wrap {
        border: none;
      }

      img {
        width: 17px;
        height: 17px;
      }

      .el-collapse-item__header {
        font-size: 32px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #000000;
        border: none;
        margin: 0 53px;
        justify-content: space-between;
        transition: none;

        .el-collapse-item__arrow {
          display: none;
        }
      }
    }

    ::v-deep .s {
      .el-collapse {
        border: none;
      }

      img {
        width: 17px;
        height: 17px;
      }

      .el-collapse-item__header {
        font-size: 28px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #000000;
        border: none;
        margin: 0 53px;
        justify-content: space-between;
        transition: none;

        .el-collapse-item__arrow {
          display: block;
          font-size: 22px;
          margin: 0 0 0 auto;
        }
      }

      .th_cont {
        margin: 0 53px;

        div {
          margin: 20px 0;
          font-size: 25px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #808080;
        }

        .s_cont {
          width: 50%;
          display: inline-block;
          text-align: center;

          .img_car {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .s_cont1 {
          width: 100%;
          display: block;
          text-align: left;
        }
      }

      .you {
        width: 9px;
        height: 15px;
      }

      .no_children {
        font-size: 28px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #808080;
        height: 58px;
        line-height: 58px;
        margin: 25px 53px;
      }
    }
  }
}
</style>
