<template>
  <div
    class="home"
  >
    <!-- <HomeSide></HomeSide> -->
    <HomeSwiper />
    <HomeCar />
    <HomeTechnology />
    <HomeStore />
    <HomeServer />
  </div>
</template>

<script>
// import HomeSide from './components/side.vue'
import HomeSwiper from './components/Swiper.vue'
import HomeCar from './components/car.vue'
import HomeTechnology from './components/technology.vue'

import HomeStore from './components/store.vue'
import HomeServer from './components/server.vue'

export default {
  name: 'HomeView',
  components: {
    // HomeSide,
    HomeSwiper,
    HomeCar,
    HomeTechnology,
    HomeStore,
    HomeServer
  },
  data() {
    return {}
  },
  mounted() {
    document.title = '新日，掌握核心科技 出行更有乐趣'
    // document.title = '新日，超高端 智能电动车'
  },
  watch: {},
  methods: {}
}
</script>
<style scoped lang="scss">
.home {}
.section {
  width: 100%;
  min-width: 960px;
  // height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}
</style>
