<template>
  <div class="HomeTechnology" v-if="imgSrc">
    <img :src="imgSrc" alt="">
    <div class="click" @click="handleJump" v-if=url>
      <span>点击探索</span>
      <img src="@/assets/home/arraw_m.png" alt="">
    </div>
  </div>
</template>
<script>
import { getForefrontTech } from '@/request/api'
export default {
  name: 'HomeTechnology',
  data() {
    return {
      imgSrc: '',
      url: '',
    }
  },
  setup() {
    return {};
  },
  methods: {
    handleJump() {
      this.$router.push(this.url)
      window._hmt.push(['_trackEvent', '官网首页', '前沿技术', '双芯双动力系统 2.0']);
    }
  },
  mounted() {
    getForefrontTech().then(res => {
      console.log(res)
      this.imgSrc = res.data[0]?.data.mobilePicture
      this.url = res.data[0]?.data.url
    })
  }
};
</script>
<style scoped lang="scss">
.HomeTechnology {
  position: relative;
  width: 100%;
  // height: calc(100vh - 60px);
  // height: calc(100vh - 90px - 120px);
  height: 166.6666vw;

  img {
    display: block;
    width: 100%;
  }

  .click {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 50%;
    bottom: 125px;
    transform: translate(-50%, 0);
    width: 390px;
    height: 66px;
    border: 3px solid #fff;
    border-radius: 32px;
    font-family: 'HarmonyOS_Sans_SC_Medium';

    font-size: 26px;
    color: #fff;

    span {
      margin: 0 2px 0 0;
    }

    img {
      margin: 0 0 0 2px;
      height: 48px;
      width: auto;
    }
  }
}
</style>
