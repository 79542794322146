<template>
  <div class="home">
    <Swiper></Swiper>
    <HomeCar></HomeCar>
    <HomeTechnology></HomeTechnology>
    <HomeStore></HomeStore>
    <HomeServer></HomeServer>
  </div>
</template>

<script>
import Swiper from './componentsMobile/Swiper.vue'
import HomeCar from './componentsMobile/car.vue'
import HomeTechnology from './componentsMobile/technology.vue'

import HomeStore from './componentsMobile/store.vue'
import HomeServer from './componentsMobile/server.vue'

export default {
  name: 'HomeView',
  components: {
    Swiper,
    HomeCar,
    HomeTechnology,
    HomeStore,
    HomeServer
  },
  mounted() {
    document.title = '新日，掌握核心科技 出行更有乐趣'
  },
  methods: {
    increment() {
      this.$store.commit('increment')
      console.log(this.$store.state.count)
    }
  }
}
</script>
<style scoped lang="scss"></style>
