import HomeView from '@/views/home/IndexView.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 使用指南
  {
    path: '/ServiceDirectory',
    name: 'ServiceDirectory',
    component: () => import('@/views/ServiceDirectory/index.vue'),
    redirect: 'SanBao',
    children: [
      // 零部件三包标准
      {
        path: '/SanBao',
        name: 'SanBao',
        component: () => import('@/views/ServiceDirectory/SanBao.vue')
      },
      // 常见问题解答
      {
        path: '/Answer',
        name: 'Answer',
        component: () => import('@/views/ServiceDirectory/Answer.vue')
      },
      // 车辆保养
      {
        path: '/maintain',
        name: 'maintain',
        component: () => import('@/views/ServiceDirectory/maintain.vue')
      }
    ]
  },
  // 防伪查询
  {
    path: '/AntFake',
    name: 'AntFake',
    component: () => import('@/views/AntFake/index.vue'),
  },
  // 预约试驾
  {
    path: '/TestDrive',
    name: 'TestDrive',
    component: () => import('@/views/TestDrive/index.vue'),
  },
  // 智享服务
  {
    path: '/enjoyment',
    name: 'enjoyment',
    component: () => import('@/views/enjoyment/index.vue'),
    redirect: 'Rescue',
    children: [{
      path: '/Rescue',
      name: 'Rescue',
      component: () => import('@/views/enjoyment/Rescue.vue')
    },
    {
      path: '/AfterSale',
      name: 'AfterSale',
      component: () => import('@/views/enjoyment/AfterSale.vue')
    }
    ]
  },
  // 了解新日
  {
    path: '/Understand',
    name: 'Understand',
    component: () => import('@/views/BrandStory/index.vue'),
    redirect: 'BrandStory',
    children: [
      // 品牌简介
      {
        path: '/BrandStory',
        name: 'BrandStory',
        component: () => import('@/views/BrandStory/BrandStory.vue')
      },
      // 品牌愿景
      {
        path: '/Vision',
        name: 'Vision',
        component: () => import('@/views/BrandStory/Vision.vue')
      },
      // 品牌荣誉
      {
        path: '/Honor',
        name: 'Honor',
        component: () => import('@/views/BrandStory/Honor.vue')
      },
      // 合作伙伴
      {
        path: '/Cooperative',
        name: 'Cooperative',
        component: () => import('@/views/BrandStory/Cooperative.vue')
      },
      // 技术实力
      {
        path: '/Technological',
        name: 'Technological',
        component: () => import('@/views/BrandStory/Technological.vue')
      },
    ]
  },
  // 新日里程碑
  {
    path: '/MileageView',
    name: 'MileageView',
    component: () => import('@/views/MileageView/MileageView.vue'),
  },
  // 加入我们
  {
    path: '/JoinUs',
    name: 'JoinUs',
    component: () => import('@/views/JoinUs/JoinUs.vue'),
  },
  // 网点查询
  {
    path: '/OutletQuery',
    name: 'OutletQuery',
    component: () => import('@/views/OutletQuery/OutletQuery.vue'),
  },
  // 锂电池页
  {
    path: '/LithiumCell',
    name: 'LithiumCell',
    component: () => import('@/views/LithiumCell/LithiumCell.vue'),
  },
  // MIKU1车型
  {
    path: '/MIKU1',
    name: 'MIKU1',
    component: () => import('@/views/MIKU1/indexView.vue'),
    redirect: 'MIKU1Detail',
    children: [{
      path: '/MIKU1Detail',
      name: 'MIKU1Detail',
      component: () => import('@/views/MIKU1/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 102,
        title: "MIKU 1",
        code: 1
      }
    },
    {
      path: '/MIKU1Config',
      name: 'MIKU1Config',
      component: () => import('@/views/MIKU1/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 102,
        title: "MIKU 1",
        code: 2
      }
    }
    ]
  },
  // MIKUSuper车型
  {
    path: '/MIKUSuper',
    name: 'MIKUSuper',
    component: () => import('@/views/MIKUSuper/indexView.vue'),
    redirect: 'MIKUSuperDetail',
    children: [{
      path: '/MIKUSuperDetail',
      name: 'MIKUSuperDetail',
      component: () => import('@/views/MIKUSuper/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 104,
        title: "MIKU Super",
        code: 1
      }
    },
    {
      path: '/MIKUSuperConfig',
      name: 'MIKUSuperConfig',
      component: () => import('@/views/MIKUSuper/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 104,
        title: "MIKU Super",
        code: 2
      }
    }
    ]
  },
  // CICI车型
  {
    path: '/CICI',
    name: 'CICI',
    component: () => import('@/views/CICI/index.vue'),
    redirect: 'CICIDetail',
    children: [{
      path: '/CICIDetail',
      name: 'CICIDetail',
      component: () => import('@/views/CICI/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 101,
        title: "CiCi",
        code: 1
      }
    },
    {
      path: '/CICIConfig',
      name: 'CICIConfig',
      component: () => import('@/views/CICI/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 101,
        title: "CiCi",
        code: 2
      }
    }
    ]
  },
  // 花仙子车型
  {
    path: '/Pixie',
    name: 'Pixie',
    component: () => import('@/views/Pixie/indexView.vue'),
    redirect: 'PixieDetail',
    children: [{
      path: '/PixieDetail',
      name: 'PixieDetail',
      component: () => import('@/views/Pixie/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 100,
        title: "花仙子",
        code: 1
      }
    },
    {
      path: '/PixieConfig',
      name: 'PixieConfig',
      component: () => import('@/views/Pixie/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 100,
        title: "花仙子",
        code: 2
      }
    }
    ]
  },
  // 幻影F9
  {
    path: '/HyF9',
    name: 'HyF9',
    component: () => import('@/views/HyF9/indexView.vue'),
    redirect: 'HyF9Detail',
    children: [{
      path: '/HyF9Detail',
      name: 'HyF9Detail',
      component: () => import('@/views/HyF9/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 103,
        title: "幻影F9",
        code: 1
      }
    },
    {
      path: '/HyF9Config',
      name: 'HyF9Config',
      component: () => import('@/views/HyF9/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 103,
        title: "幻影F9",
        code: 2
      }
    }
    ]
  },
  // 宇腾H3
  {
    path: '/YtH3',
    name: 'YtH3',
    component: () => import('@/views/YtH3/indexView.vue'),
    redirect: 'YtH3Detail',
    children: [{
      path: '/YtH3Detail',
      name: 'YtH3Detail',
      component: () => import('@/views/YtH3/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 105,
        title: "宇腾H3",
        code: 1
      }
    },
    {
      path: '/YtH3Config',
      name: 'YtH3Config',
      component: () => import('@/views/YtH3/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 105,
        title: "宇腾H3",
        code: 2
      }
    }
    ]
  },
  // 最新资讯
  // {
  //   path: '/News',
  //   name: 'News',
  //   component: () => import('@/views/News/NewsExpress.vue')
  // },
  // // 最新资讯详情
  // {
  //   path: '/InfoDetails',
  //   name: 'InfoDetails',
  //   component: () => import('@/views/News/InfoDetails.vue')
  // },
  // 最新资讯
  {
    path: '/News',
    name: 'News',
    component: () => import('@/views/News/index.vue'),
    redirect: 'NewsExpress',
    children: [
      // 新闻速递
      {
        path: '/NewsExpress',
        name: 'NewsExpress',
        component: () => import('@/views/News/NewsExpress.vue')
      },
      // 口碑文章
      {
        path: '/PublicPraise',
        name: 'PublicPraise',
        component: () => import('@/views/News/PublicPraise.vue')
      },
      // 测评专栏
      {
        path: '/Evaluation',
        name: 'Evaluation',
        component: () => import('@/views/News/Evaluation.vue')
      },
      // 详情
      {
        path: '/InfoDetails',
        name: 'InfoDetails',
        component: () => import('@/views/News/InfoDetails.vue')
      },
      // 详情
      {
        path: '/Publicity',
        name: 'Publicity',
        component: () => import('@/views/News/Publicity.vue')
      },
    ]
  },
  // 新日智选
  {
    path: '/Goods',
    name: 'Goods',
    component: () => import('@/views/Goods/index.vue'),
  },
  // 召回公共
  {
    path: '/CallBack',
    name: 'CallBack',
    component: () => import('@/views/CallBack/index.vue'),
  },
  // 控制系统
  {
    path: '/ControlSystem',
    name: 'ControlSystem',
    component: () => import('@/views/ControlSystem/index.vue'),
  },
  // 成为供应商
  {
    path: '/Supplier1',
    name: 'Supplier1',
    component: () => import('@/views/Supplier/index.vue'),
    redirect: 'Supplier',
    children: [{
      path: '/Supplier',
      name: 'Supplier',
      component: () => import('@/views/Supplier/Supplier.vue')
    },
    {
      path: '/Tendering',
      name: 'Tendering',
      component: () => import('@/views/Supplier/Tendering.vue')
    },
    ]
  },
  // 成为经销商
  {
    path: '/Dealer',
    name: 'Dealer',
    component: () => import('@/views/Dealer/index.vue'),
  },
  // 社会招聘
  {
    path: '/SocialRecruit',
    name: 'SocialRecruit',
    component: () => import('@/views/SocialRecruit/index.vue'),
  },
  // 社会招聘详情
  {
    path: '/SocialRecruitDetail',
    name: 'SocialRecruitDetail',
    component: () => import('@/views/SocialRecruit/detail.vue'),
  },
  // 校园招聘
  {
    path: '/CampusRecruit',
    name: 'CampusRecruit',
    component: () => import('@/views/CampusRecruit/index.vue'),
  },
  // 校园招聘详情
  {
    path: '/CampusRecruitDetail',
    name: 'CampusRecruitDetail',
    component: () => import('@/views/CampusRecruit/detail.vue'),
  },
  // 双芯双动力系统
  {
    path: '/DualPower',
    name: 'DualPower',
    component: () => import('@/views/DualPower/index.vue'),
  },
  // 能力与发展
  {
    path: '/Ability',
    name: 'Ability',
    component: () => import('@/views/Ability/IndexView.vue'),
  },
  // 下载中心登录
  {
    path: '/Download',
    name: 'Download',
    component: () => import('@/views/Download/login.vue'),
  },
  // 下载中心列表
  {
    path: '/DownloadList',
    name: 'DownloadList',
    component: () => import('@/views/Download/list.vue'),
  },
  // 投诉举报
  {
    path: '/Complaint',
    name: 'Complaint',
    component: () => import('@/views/Complaint/Complaint.vue'),
  },
  // 参数页面
  {
    path: '/param/:id',
    name: 'Param',
    component: () => import('@/views/Param/param.vue'),
  },
]
