import HomeView from '@/views/mobile/home/IndexViewMobile.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 三包标准页
  {
    path: '/SanBao',
    name: 'SanBao',
    component: () => import('@/views/mobile/M_ServiceDirectory/SanBao.vue')
  }, {
    path: '/Answer',
    name: 'Answer',
    component: () => import('@/views/mobile/M_ServiceDirectory/Answer.vue')
  },
  {
    path: '/maintain',
    name: 'maintain',
    component: () => import('@/views/mobile/M_ServiceDirectory/maintain.vue')
  },
  // 品牌简介
  {
    path: '/BrandStory',
    name: 'BrandStory',
    component: () => import('@/views/mobile/M_BrandStory/M_BrandStory.vue'),
  },
  // 品牌愿景
  {
    path: '/Vision',
    name: 'Vision',
    component: () => import('@/views/mobile/M_BrandStory/Vision.vue')
  },
  // 品牌荣誉
  {
    path: '/Honor',
    name: 'Honor',
    component: () => import('@/views/mobile/M_BrandStory/Honor.vue')
  },
  // 合作伙伴
  {
    path: '/Cooperative',
    name: 'Cooperative',
    component: () => import('@/views/mobile/M_BrandStory/Cooperative.vue')
  },
  // 技术实力
  {
    path: '/Technological',
    name: 'Technological',
    component: () => import('@/views/mobile/M_BrandStory/Technological.vue')
  },
  // 新日里程碑
  {
    path: '/MileageView',
    name: 'MileageView',
    component: () => import('@/views/mobile/M_MileageView/M_MileageView.vue'),
  },
  // 加入我们
  {
    path: '/JoinUs',
    name: 'JoinUs',
    component: () => import('@/views/mobile/M_JoinUs/M_JoinUs.vue'),
  },
  // 网点查询
  {
    path: '/OutletQuery',
    name: 'OutletQuery',
    component: () => import('@/views/mobile/M_OutletQuery/M_OutletQuery.vue'),
  },
  // 防伪查询
  {
    path: '/AntFake',
    name: 'AntFake',
    component: () => import('@/views//mobile/M_AntFake/index.vue'),
  },
  // 召回公告
  {
    path: '/CallBack',
    name: 'CallBack',
    component: () => import('@/views//mobile/CallBack/index.vue'),
  },
  // 锂电池页
  {
    path: '/LithiumCell',
    name: 'LithiumCell',
    component: () => import('@/views/mobile/M_LithiumCell/M_LithiumCell.vue'),
  },
  // 智享服务
  {
    path: '/Rescue',
    name: 'Rescue',
    component: () => import('@/views/mobile/M_enjoyment/Rescue.vue')
  }, {
    path: '/AfterSale',
    name: 'AfterSale',
    component: () => import('@/views/mobile/M_enjoyment/AfterSale.vue')
  },
  // MIKU1车型
  {
    path: '/MIKU1',
    name: 'MIKU1',
    component: () => import('@/views/mobile/M_MIKU1/indexView.vue'),
    redirect: 'MIKU1Detail',
    children: [{
      path: '/MIKU1Detail',
      name: 'MIKU1Detail',
      component: () => import('@/views/mobile/M_MIKU1/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 102,
        title: "MIKU 1"
      }
    },
    // 参数配置
    {
      path: '/MIKU1Config',
      name: 'MIKU1Config',
      component: () => import('@/views/mobile/M_MIKU1/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 102,
        title: "MIKU 1"
      }
    }
    ]
  },
  // 预约试驾
  {
    path: '/TestDrive',
    name: 'TestDrive',
    component: () => import('@/views/mobile/M_TestDrive/index.vue'),
  },
  // CICI车型
  {
    path: '/CICI',
    name: 'CICI',
    component: () => import('@/views/mobile/M_CICI/indexView.vue'),
    redirect: 'CICIDetail',
    children: [{
      path: '/CICIDetail',
      name: 'CICIDetail',
      component: () => import('@/views/mobile/M_CICI/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 101,
        title: "花仙子"
      }
    },
    {
      path: '/CICIConfig',
      name: 'CICIConfig',
      component: () => import('@/views/mobile/M_CICI/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 101,
        title: "花仙子"
      }
    }
    ]
  },
  // MIKUSuper车型
  {
    path: '/MIKUSuper',
    name: 'MIKUSuper',
    component: () => import('@/views/mobile/M_MIKUSuper/indexView.vue'),
    redirect: 'MIKUSuperDetail',
    children: [{
      path: '/MIKUSuperDetail',
      name: 'MIKUSuperDetail',
      component: () => import('@/views/mobile/M_MIKUSuper/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 104,
        title: "MIKU Super"
      }
    },
    {
      path: '/MIKUSuperConfig',
      name: 'MIKUSuperConfig',
      component: () => import('@/views/mobile/M_MIKUSuper/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 104,
        title: "MIKU Super"
      }
    }
    ]
  },
  // 花仙子车型
  {
    path: '/Pixie',
    name: 'Pixie',
    component: () => import('@/views/mobile/Pixie/indexView.vue'),
    redirect: 'PixieDetail',
    children: [{
      path: '/PixieDetail',
      name: 'PixieDetail',
      component: () => import('@/views/mobile/Pixie/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 100,
        title: "花仙子"
      }
    },
    // 锂电池页
    {
      path: '/PixieConfig',
      name: 'PixieConfig',
      component: () => import('@/views/mobile/Pixie/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 100,
        title: "花仙子 | 车型 | 新日，掌握核心科技 出行更有乐趣"
      }
    }
    ]
  },
  // 幻影F9
  {
    path: '/HyF9',
    name: 'HyF9',
    component: () => import('@/views/mobile/HyF9/indexView.vue'),
    redirect: 'HyF9Detail',
    children: [{
      path: '/HyF9Detail',
      name: 'HyF9Detail',
      component: () => import('@/views/mobile/HyF9/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 103,
        title: "幻影F9"
      }
    },
    {
      path: '/HyF9Config',
      name: 'HyF9Config',
      component: () => import('@/views/mobile/HyF9/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 103,
        title: "幻影F9"
      }
    }
    ]
  },
  // 宇腾H3
  {
    path: '/YtH3',
    name: 'YtH3',
    component: () => import('@/views/mobile/YtH3/indexView.vue'),
    redirect: 'YtH3Detail',
    children: [{
      path: '/YtH3Detail',
      name: 'YtH3Detail',
      component: () => import('@/views/mobile/YtH3/DetailInfor/DetailInfor.vue'),
      meta: {
        id: 105,
        title: "宇腾H3",
        code: 1
      }
    },
    {
      path: '/YtH3Config',
      name: 'YtH3Config',
      component: () => import('@/views/mobile/YtH3/ParamConfig/ParamConfig.vue'),
      meta: {
        id: 105,
        title: "宇腾H3",
        code: 2
      }
    }
    ]
  },
  // 新闻速递
  {
    path: '/NewsExpress',
    name: 'NewsExpress',
    component: () => import('@/views/mobile/News/NewsExpress.vue')
  },
  // 最新资讯详情
  {
    path: '/InfoDetails',
    name: 'InfoDetails',
    component: () => import('@/views/mobile/News/InfoDetails.vue')
  },
  // 口碑文章
  {
    path: '/PublicPraise',
    name: 'PublicPraise',
    component: () => import('@/views/mobile/News/PublicPraise.vue')
  },
  // 测评专栏
  {
    path: '/Evaluation',
    name: 'Evaluation',
    component: () => import('@/views/mobile/News/Evaluation.vue')
  },
  {
    path: '/Publicity',
    name: 'Publicity',
    component: () => import('@/views/mobile/News/Publicity.vue')
  },
  // // 最新资讯
  // {
  //   path: '/News',
  //   name: 'News',
  //   component: () => import('@/views/mobile/News/NewsExpress.vue'),
  //   redirect: 'NewsExpress',
  //   children: [{
  //     path: '/NewsExpress',
  //     name: 'NewsExpress',
  //     component: () => import('@/views/mobile/News/NewsExpress.vue')
  //   },
  //   {
  //     path: '/InfoDetails',
  //     name: 'InfoDetails',
  //     component: () => import('@/views/mobile/News/InfoDetails.vue')
  //   },
  //   ]
  // },
  // 新日智选
  {
    path: '/Goods',
    name: 'Goods',
    component: () => import('@/views/mobile/Goods/index.vue'),
  },
  // 双芯双动力系统
  {
    path: '/DualPower',
    name: 'DualPower',
    component: () => import('@/views/mobile/DualPower/index.vue'),
  },
  // 控制系统
  {
    path: '/ControlSystem',
    name: 'ControlSystem',
    component: () => import('@/views/mobile/M_ControlSystem/index.vue'),
  },
  // 成为供应商
  {
    path: '/Supplier',
    name: 'Supplier',
    component: () => import('@/views/mobile/Supplier/Supplier.vue'),
  },
  // 招标中心
  {
    path: '/Tendering',
    name: 'Tendering',
    component: () => import('@/views/mobile/Supplier/Tendering.vue'),
  },
  // 成为经销商
  {
    path: '/Dealer',
    name: 'Dealer',
    component: () => import('@/views/mobile/Dealer/index.vue'),
  },
  // 社会招聘
  {
    path: '/SocialRecruit',
    name: 'SocialRecruit',
    component: () => import('@/views/mobile/SocialRecruit/index.vue'),
  },
  // 社会招聘详情
  {
    path: '/SocialRecruitDetail',
    name: 'SocialRecruitDetail',
    component: () => import('@/views/mobile/SocialRecruit/detail.vue'),
  },
  // 校园招聘
  {
    path: '/CampusRecruit',
    name: 'CampusRecruit',
    component: () => import('@/views/mobile/CampusRecruit/index.vue'),
  },
  // 校园招聘详情
  {
    path: '/CampusRecruitDetail',
    name: 'CampusRecruitDetail',
    component: () => import('@/views/mobile/CampusRecruit/detail.vue'),
  },
  // 能力与发展
  {
    path: '/Ability',
    name: 'Ability',
    component: () => import('@/views/mobile/Ability/IndexView.vue'),
  },
  // 下载中心登录
  {
    path: '/Download',
    name: 'Download',
    component: () => import('@/views/mobile/Download/login.vue'),
  },
  // 下载中心列表
  {
    path: '/DownloadList',
    name: 'DownloadList',
    component: () => import('@/views/mobile/Download/list.vue'),
  },
  // 投诉举报
  {
    path: '/Complaint',
    name: 'Complaint',
    component: () => import('@/views/mobile/Complaint/Complaint.vue'),
  },
  // 参数页面
  {
    path: '/param/:id',
    name: 'Param',
    component: () => import('@/views/mobile/Param/param.vue'),
  },
]

