<template>
  <div id="app">
    <Header v-if="!isMibile" :class="{ 'to_top': isShow }"></Header>
    <MobileHeader v-if="isMibile"></MobileHeader>
    <router-view />
    <Footer v-if="!isMibile"></Footer>
    <MobileFooter v-if="isMibile"></MobileFooter>
    <HomeSide v-if="!isMibile"></HomeSide>
  </div>
</template>

<script>
import HomeSide from '@/views/home/components/side.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MobileHeader from '@/components/mobile/Header.vue'
import MobileFooter from '@/components/mobile/Footer.vue'
import { _throttle } from "@/utils/util.js"
import { isMibile } from '@/utils/index'
import 'animate.css'
export default {
  components: {
    Header,
    Footer,
    MobileHeader,
    MobileFooter,
    HomeSide,
  },
  data() {
    return {
      isMibile: isMibile,
      isShow: false,
    }
  },
  methods: {
    // 下拉隐藏头部
    // handleScroll: _throttle(function () {
    //   const el = document.documentElement.clientHeight;
    //   const top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
    //   if (this.$route.meta.code == 1) {
    //     if (top > el - 60) {
    //       this.isShow = true
    //     } else {
    //       this.isShow = false
    //     }
    //   }
    //   if (this.$route.meta.code == 2) {
    //     if (top > 60) {
    //       this.isShow = true
    //     } else {
    //       this.isShow = false
    //     }
    //   }
    // }, 500),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style lang="scss">
@font-face {
  font-family: HarmonyOS_Sans_Black;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Black.ttf') format('truetype');
}

@font-face {
  font-family: HarmonyOS_Sans_Bold;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Bold.ttf') format('truetype');
}

@font-face {
  font-family: HarmonyOS_Sans_Light;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Light.ttf') format('truetype');
}

@font-face {
  font-family: HarmonyOS_Sans_Medium;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf') format('truetype');
}

@font-face {
  font-family: HarmonyOS_Sans_Regular;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Regular.ttf') format('truetype');
}

@font-face {
  font-family: HarmonyOS_Sans_Thin;
  src: url('./assets/font/HarmonyOS/HarmonyOS_Sans/HarmonyOS_Sans_Thin.ttf') format('truetype');
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#app {
  font-family: 'HarmonyOS_Sans_Regular';
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

img {
  width: 100%;
  height: 100%;
}

.clear {
  clear: both;
}

.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}

.to_top {
  display: none;
}
</style>
