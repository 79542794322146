<template>
  <div class="header">
    <div class="wrap">
      <div class="head">
        <div class="logo" @click="handleNav('/')">
          <img src="@/assets/common/head_logo.png">
        </div>
        <ul>
          <li v-for="item in list" :key="item.title" class="nav" @click="handleNav(item.title)">
            {{ item.title }}
          </li>
        </ul>
        <div class="lang">
          <img @click="handleNav('https://www.sunraev.com/')" src="@/assets/common/lang.png">
          <span @click="handleNav('en')">EN</span>
        </div>
      </div>
    </div>
    <div class="side_bg" v-if="sub_nav" @click="handleClose()">
      <img v-if="third_cur.image" :src="third_cur.image">
    </div>
    <div class="side" v-if="sub_nav">
      <ul class="sub_nav">
        <li v-for="item in sub_nav" :key="item.title" :class="{ active: sub_cur === item.title }"
          @click="handleNavSub(item, true)" @mousemove="handleNavSub(item, false)">
          {{ item.title }}
        </li>
      </ul>
      <ul class="sub_nav third_nav" v-if="third_nav">
        <li v-for="item in third_nav" :key="item.title" :class="{ active: third_cur.title === item.title }"
          @click="handleNavThird(item, true)" @mouseover="handleNavThird(item, false)">
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import nav_bg from '@/assets/home/nav_bg.png'
import nav_bg_hxz from '@/assets/common/header/hxz.jpg'
import nav_bg_cc from '@/assets/common/header/cc.jpg'
import nav_bg_f9 from '@/assets/common/header/f9.jpg'
import nav_bg_m1 from '@/assets/common/header/miku_01.jpg'
import nav_bg_ms from '@/assets/common/header/miku_super.jpg'
import { getMenu } from '@/request/api'

export default {
  name: 'HeaderComponent',
  data() {
    return {
      nav_cur: '',
      sub_cur: '',
      third_cur: {},
      sub_nav: null,
      third_nav: null,
      navImgUrl: '',
      list: [
        // {
        //   text: '探索车型',
        //   children: [
        //     {
        //       text: '双芯双动力系列',
        //       path: '',
        //       children: [
        //         {
        //           text: '花仙子',
        //           point_text: '花仙子',
        //           path: '/Pixie',
        //           img: nav_bg_hxz,
        //         }, {
        //           text: 'CiCi',
        //           point_text: 'CiCi',
        //           path: '/CICI',
        //           img: nav_bg_cc,
        //         },
        //         // {
        //         //   text: '幻影F9',
        //         //   point_text: '幻影F9',
        //         //   path: '/HyF9',
        //         //   img: nav_bg_f9,
        //         // },
        //       ],
        //     }, {
        //       text: 'MIKU 系列',
        //       path: '',
        //       children: [
        //         {
        //           text: 'MIKU 1',
        //           point_text: 'MIKU 1',
        //           path: '/MIKU1',
        //           img: '@/assets/common/header/miku_01.jpg',
        //           img: nav_bg_m1,
        //         }, {
        //           text: 'MIKU Super',
        //           point_text: 'MIKU Super',
        //           path: '/MIKUSuper',
        //           img: nav_bg_ms,
        //         },
        //       ],
        //     }
        //   ],
        // }, {
        //   text: '前沿技术',
        //   children: [
        //     {
        //       text: '汽车级锂电池',
        //       point_text: '汽车级锂电池',
        //       path: '/LithiumCell'
        //     },
        //     {
        //       text: '双芯双动力系统2.0',
        //       point_text: '双芯双动力系统2.0',
        //       path: '/DualPower'
        //     },
        //     {
        //       text: '智能操控系统',
        //       point_text: '智能操控系统',
        //       path: '/ControlSystem'
        //     },
        //   ],
        // }, {
        //   text: '车主服务',
        //   children: [
        //     {
        //       text: '使用指南',
        //       point_text: '零部件三包标准',
        //       path: '/SanBao',
        //     },
        //     // {
        //     //   text: '网点查询',
        //     //   point_text: '网点查询',
        //     //   path: '/OutletQuery',
        //     // }, 
        //     {
        //       text: '智享服务',
        //       point_text: '一键救援',
        //       path: '/enjoyment',
        //     }, {
        //       text: '智选周边',
        //       point_text: '智选周边',
        //       path: '/Goods',
        //     }, {
        //       text: '防伪查询',
        //       point_text: '防伪查询',
        //       path: '/AntFake',

        //     },
        //   ],
        // }, {
        //   text: '探索新日',
        //   children: [
        //     {
        //       text: '了解新日',
        //       point_text: '品牌简介',
        //       path: '/BrandStory'
        //     }, {
        //       text: '新日里程',
        //       point_text: '新日里程',
        //       path: '/MileageView'
        //     }, {
        //       text: '最新资讯',
        //       point_text: '最新资讯',
        //       path: '/News'
        //     }, {
        //       text: '加入我们',
        //       point_text: '加入我们',
        //       path: '/JoinUs'
        //     },
        //   ],
        // },
      ],
    }
  },
  methods: {
    handleNav(n) {
      if (n === '/') {
        this.$router.push(n)
        document.querySelector('meta[name="keywords"]').setAttribute('content', '')
        document.querySelector('meta[name="description"]').setAttribute('content', '新日集团成立于1999年，从事绿色交通工具研发、生产与销售，是国内首家登录 A 股电动自行车企业。产品远销欧美和东南亚市场，出口全球近100个国家和地区。')
      } else if (n === 'https://www.sunraev.com/') {
        window.open(n, '_blank')
      } else if (n === 'en') {
        const pathname = window.location.pathname
        const origin = window.location.origin
        let u = 'https://en.xinri.com'
        if (origin.includes('dev') || origin.includes('localhost')) {
          u = 'https://xinri-dev.powermarketing.com.cn'
        }
        window.location.href = u + pathname
      }
      const sub_nav = this.list.find(v => v.title === n)?.children
      this.nav_cur = n
      this.sub_nav = sub_nav
      this.sub_cur = ''
      this.third_cur = {}
      this.third_nav = null
    },
    handleNavSub(item, jump) {
      this.sub_cur = item.title
      this.third_nav = item.children
      document.querySelector('meta[name="keywords"]').setAttribute('content', '')
      document.querySelector('meta[name="description"]').setAttribute('content', '新日集团成立于1999年，从事绿色交通工具研发、生产与销售，是国内首家登录 A 股电动自行车企业。产品远销欧美和东南亚市场，出口全球近100个国家和地区。')
      if (item.path && jump && item.path != '#') {
        if (item.path.indexOf("/param") != -1 && this.$route.path.indexOf("/param") != -1) {
          this.$router.push(item.path);
          location.reload();
        } else {
          this.$router.push(item.path)
        }
        this.sub_nav = null
      }
      if (jump) {
        if (this.nav_cur == '车主服务' || this.nav_cur == '探索新日') {
          window._hmt.push(['_trackEvent', '头部导航栏', this.nav_cur + '-' + item.title, item.point_text]);
        } else {
          window._hmt.push(['_trackEvent', '头部导航栏', this.nav_cur, item.point_text]);
        }
      }
    },
    handleNavThird(item, jump) {
      this.third_cur = item
      document.querySelector('meta[name="keywords"]').setAttribute('content', '')
      document.querySelector('meta[name="description"]').setAttribute('content', '新日集团成立于1999年，从事绿色交通工具研发、生产与销售，是国内首家登录 A 股电动自行车企业。产品远销欧美和东南亚市场，出口全球近100个国家和地区。')
      if (item.path && jump && item.path != '#') {
        if (item.path.indexOf("/param") != -1 && this.$route.path.indexOf("/param") != -1) {
          this.$router.push(item.path);
          location.reload();
        }
        this.sub_nav = null
        this.$router.push(item.path)
      }
      if (jump) {
        if (this.nav_cur == '车主服务' || this.nav_cur == '探索新日') {
          window._hmt.push(['_trackEvent', '头部导航栏', this.nav_cur + '-' + item.title, item.point_text]);
        } else {
          window._hmt.push(['_trackEvent', '头部导航栏', this.nav_cur, item.point_text]);
        }
      }
    },
    handleClose() {
      this.nav_cur = ''
      this.sub_cur = ''
      this.third_cur = {}
      this.sub_nav = null
      this.third_nav = null
    },
    handleClick(car) {
      if (car.image) this.navImgUrl = car.image
      this.$router.push(car.path)
    }
  },
  mounted() {
    getMenu({ type: 0 }).then(res => {
      // console.log(res)
      this.list = res.data;
    })
  }
}
</script>
<style scoped lang="scss">
.header {
  height: 60px;
}

.wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 60px;
  background: #fff;
}

.head {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 1024px;
  height: 100%;
  padding: 0 200px;

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  li {
    margin: 0 30px;
    font-size: 14px;
    color: #1c1c1f;

    font-family: "HarmonyOS_Sans_SC_Regular";
    cursor: pointer;
  }
}

.logo {
  height: 28px;
  cursor: pointer;
  margin: 0 40px 0 0;

  img {
    height: 100%;
    width: auto;
  }
}

.lang {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;

  img {
    width: auto;
    height: 15px;
  }

  span {
    margin: 0 0 0 7px;
    font-size: 14px;
    font-family: "HarmonyOS_Sans_SC_Regular";
  }
}

.side_bg {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(12, 11, 15, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.side {
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 760px;
  background: rgba(12, 11, 15, 0.9);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 100px 0 0 200px;

  .sub_nav {
    min-width: 162px;

    li {
      font-size: 18px;
      font-family: "HarmonyOS_Sans_SC_Medium";
      padding: 35px 0;
      color: #9e9f9f;
      cursor: pointer;

      &.active {
        color: #fff;
      }
    }
  }

  .third_nav {
    li {
      position: relative;
      color: #fff;

      &:before {
        position: absolute;
        top: 42px;
        left: -10px;
        display: none;
        content: " ";
        width: 5px;
        height: 5px;
        background: #fff;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        bottom: 23px;
        left: 0;
        display: none;
        width: 110px;
        height: 2px;
        content: "";
        background: #ffc600;
      }

      &.active::before,
      &.active::after {
        display: block;
      }
    }
  }
}
</style>
